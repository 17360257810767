.line {
    max-width: 1250px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.2px solid #211b5994;
}

footer {
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #F2FBFF;
}

.logo_name {
    max-width: 300px;
    max-height: 160px;
    cursor: pointer;
}

footer .content {
    max-width: 1250px;
    margin: auto;
    padding: 30px 40px 40px 40px;
}

footer .content .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.content .top .logo-details {
    color: #fff;
    font-size: 30px;
}

.content .top .media-icons {
    display: flex;
}

.content .top .media-icons a {
    height: 40px;
    width: 40px;
    margin: 0 8px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    transition: all 0.4s ease;
}

.top .media-icons a:nth-child(1) {
    background: #211B59;
    font-size: 22px;
}

.top .media-icons a:nth-child(1):hover {
    color: #4267B2;
    background: #fff;
}

.top .media-icons a:nth-child(2) {
    background: #211B59;
}

.top .media-icons a:nth-child(2):hover {
    color: #1DA1F2;
    background: #fff;
}

.top .media-icons a:nth-child(3) {
    background: #211B59;
}

.top .media-icons a:nth-child(3):hover {
    color: #E1306C;
    background: #fff;
}

.top .media-icons a:nth-child(4) {
    background: #211B59;
}

.top .media-icons a:nth-child(4):hover {
    color: #FF0000;
    background: #fff;
}

footer .content .link-boxes {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

footer .content .link-boxes .box {
    width: calc(100% / 5 - 10px);
}

.content .link-boxes .box .link_name {
    color: #211B59;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
}

.link-boxes .box .link_name::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 35px;
    background: #211B59;
}

.content .link-boxes .box li {
    margin: 6px 0;
    list-style: none;
    color: #211B59;
}

.content .link-boxes .box li a {
    color: #211B59;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.4s ease;
    cursor: pointer;
}

.content .link-boxes .box li a:hover {
    opacity: 1;
    text-decoration: underline;
}

footer .bottom-details {
    width: 100%;
}

footer .bottom-details .bottom_text {
    max-width: 1250px;
    margin: auto;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-details .bottom_text span,
.bottom-details .bottom_text a {
    font-size: 14px;
    font-weight: 300;
    color: #0F0844;
    opacity: 0.8;
    text-decoration: none;
}

.bottom-details .bottom_text a:hover {
    opacity: 1;
    text-decoration: underline;
}

.bottom-details .bottom_text a {
    margin-right: 10px;
}

@media (max-width: 900px) {
    footer .content .link-boxes {
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    .logo_name {
        max-width: 300px;
        max-height: 250px;
    }

    footer {
        position: relative;
        min-width: 100%;
    }

    .content {
        width: 80%;
    }

    .content .top .logo-details {
        font-size: 26px;
    }

    .content .top .media-icons a {
        height: 50px;
        width: 50px;
        font-size: 25px;
        line-height: 35px;
    }

    .content .top .media-icons a svg {
        margin-top: 8px;
    }

    .content .link-boxes .box .link_name {
        font-size: 25px;
    }

    .content .link-boxes .box li a {
        font-size: 18px;
    }

    footer .content .link-boxes .box {
        width: calc(100% / 3 - 10px);
    }

    .bottom-details .bottom_text span,
    .bottom-details .bottom_text a {
        font-size: 15px;
        text-align: center;
    }


}


@media (min-width: 769px) and (max-width: 992px) {
    .logo_name {
        max-width: 300px;
        max-height: 250px;
    }

    footer {
        position: relative;
        min-width: 196.5%;
    }

    .content {
        width: 80%;
    }

    .content .top .logo-details {
        font-size: 26px;
    }

    .content .top .media-icons a {
        height: 50px;
        width: 50px;
        font-size: 25px;
        line-height: 35px;
    }

    .content .top .media-icons a svg {
        margin-top: 8px;
    }

    .content .link-boxes .box .link_name {
        font-size: 28px;
    }

    .content .link-boxes .box li a {
        font-size: 23px;
    }

    footer .content .link-boxes .box {
        width: calc(100% / 3 - 10px);
    }

    .bottom-details .bottom_text span,
    .bottom-details .bottom_text a {
        font-size: 18px;
    }
}

@media (max-width: 520px) {
    footer::before {
        top: 145px;
    }

    footer .content .top {
        flex-direction: column;
    }

    .content .top .media-icons {
        margin-top: 16px;
    }

    footer .content .link-boxes .box {
        width: calc(100% / 2 - 10px);
    }
}


@media (min-width: 1024px) and (max-width: 1366px) {
	footer  {
	  width: 100%;
	}
  }