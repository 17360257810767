.Terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: url('../../assets/bg-website.png') no-repeat center center/cover;
}

.contentTerms {
  margin-top: 180px;
}

.Terms h1 {
  text-align: center;
  color: #211b59;
  margin-bottom: 2rem;
}

h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #211b59;
}

h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #211b59;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}

.Terms .contentTerms {
  max-width: 70rem;
}

.Terms h2,
.Terms h3 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.Terms p {
  margin-bottom: 10px;
}



@media (max-width: 768px) {
  .Terms {
    min-height: calc(100vh - 180px);
  }

  .Terms .contentTerms {
    width: 90%;
  }

  .Terms h1 {
    font-size: 35px;
  }

  .Terms h2 {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .Terms h3 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .Terms p {
    font-size: 18px;
    margin-bottom: 8px;
    text-align: justify;
  }
}