.Privacy {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: url('../../assets/bg-website.png') no-repeat center center/cover;
}

.contentPrivacy {
  margin-top: 180px;
}


.Privacy h1 {
  text-align: center;
  color: #211b59;
  margin-bottom: 2rem;
}

.Privacy h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #211b59;
}

.Privacy h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #211b59;
}

.Privacy p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  text-align: justify;
}

.Privacy .contentPrivacy {
  max-width: 70rem;
}

.Privacy h2,
.Privacy h3 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.Privacy p {
  margin-bottom: 10px;
}



@media (max-width: 768px) {
  .Privacy {
    min-height: calc(100vh - 180px);
  }

  .Privacy .contentPrivacy {
    width: 90%;
  }

  .Privacy h1 {
    font-size: 35px;
  }

  .Privacy h2 {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .Privacy h3 {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .Privacy p {
    font-size: 18px;
    margin-bottom: 8px;
    text-align: justify;
  }
}