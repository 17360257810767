.Subscription {
  width: 100%;
  height: 100vh;
  background: url('../../assets/bg-website.png') no-repeat center center/cover;
}

.content-Subscription {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.SubscriptionCentered-content {
  text-align: center;
  margin-bottom: 5rem;
}

.title-Subscription {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: #211B59;
}

.SubscriptionCentered-button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #0277B5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .Subscription {
    width: 200%;
    height: 150vh;
  }

  .SubscriptionCentered-button {
    font-size: 1.5rem;
  }

}