.faq-container {
  width: 100%;
  min-height: 100vh;
  background-color: #F2FBFF;
  margin: 0 auto;
  padding: 4rem;
}

.container-content {
  margin-top: 10rem;
}

.faq-container h1 {
  text-align: center;
  color: #211b59;
  margin-bottom: 0rem;
}

.contact-us {
  text-align: center;
  padding-top: 1rem;
  color: #211b59;
  font-size: 1.2rem;
}

.accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
  margin: 0 auto;
  width: 150%;
  max-width: 800px;
  margin-top: 2rem;
}

.accordion .accordion-item button[aria-expanded='true'] {
  border-bottom: 1px solid #0277b5;
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #211b59;
  font-size: 1.15rem;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: #0277b5;
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: #0277b5;
  border: 1px solid #0277b5;
}

.accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.accordion button .icon::before {
  display: block;
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.accordion button .icon::after {
  display: block;
  position: absolute;
  content: '';
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.accordion button[aria-expanded='true'] {
  color: #0277b5;
}

.accordion button[aria-expanded='true'] .icon::after {
  width: 0;
}

.accordion button[aria-expanded='true']+.accordion-content {
  opacity: 1;
  max-height: 15em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.accordion .accordion-content p {
  font-size: 1rem;
  font-weight: 300;
  margin: 2em 0;
  color: #211b59;
}



@media (max-width: 768px) {
  .faq-container {
    padding: 2rem;
    min-width: 100%;
  }

  .container-content {
    margin-top: 5rem;
  }

  .faq-container h1 {
    font-size: 30px;
  }

  .accordion .accordion-item {
    width: 50%;
    margin: 0 auto;
  }

  .accordion button .icon {
    right: -30px;
  }

  .contact-us {
    font-size: 1.2rem;
    padding-bottom: 50px;
  }


  .accordion button .accordion-title {
    font-size: 1rem;
  }

  .accordion .accordion-content p {
    font-size: 0.8rem;
  }

}


@media (min-width: 769px) and (max-width: 992px) {
  .faq-container {
    padding: 2rem;
    min-width: 196.5%;
  }

  .container-content {
    margin-top: 5rem;
  }

  .accordion .accordion-item {
    width: 80%;
    margin: 0 auto;
  }

  .accordion button .icon {
    right: -30px;
  }

  .contact-us {
    font-size: 1.5rem;
    padding-bottom: 50px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
	.faq-container  {
	  width: 100%;
	  min-height: 100vh;
	}
  }