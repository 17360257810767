.ourMethodology {
	width: 100%;
	height: 141vh;
	display: flex;
	align-items: center;
	background-color: #F2FBFF;
}

.section {
	margin-top: 250px;
}

.titleOurMethodology {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #211b59;
}

.containerOurMethodology {
	width: 80%;
	display: flex;
	margin: auto;
	padding-top: 100px;
	margin-right: 100px;
}

.ourMethodologycontent-section {
	width: 55%;
	color: #211b59;
	text-align: justify;
}


.image-section {
	width: 40%;
}

.image-section .card {
	width: 100%;
	height: 100%;
	perspective: 1000px;
}

.image-section .card-inner {
	width: 100%;
	height: 100%;
	position: relative;
	transform-style: preserve-3d;
	transition: transform 0.5s;
}

.image-section .card:hover .card-inner {
	transform: rotateY(180deg);
}

.image-section .card-front,
.image-section .card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
}

.image-section .card-front img,
.image-section .card-back img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	margin-top: -100px;
}

.image-section .card-front {
	transform: rotateY(0deg);
}

.image-section .card-back {
	transform: rotateY(180deg);
}



.ourMethodologycontent-section .content-point {
	display: flex;
	margin-top: 20px;
	text-align: left;
}

.ourMethodologycontent-section .content-point p {
	margin-left: 10px;
}

.ourMethodologycontent-section .content-point h5 {
	margin-left: 10px;
	font-size: 17px;
}

.image-section {
	float: right;
	width: 40%;
}

.image-section img {
	width: 100%;
	height: auto;
}

.ourMethodologycontent-section .content h3 {
	margin-top: 20px;
	color: #5d5d5d;
	font-size: 21px;
}

.ourMethodologycontent-section .content p {
	margin-top: 10px;
	font-family: sans-serif;
	font-size: 18px;
	line-height: 1.5;
}

.ourMethodologycontent-section .content .button {
	margin-top: 30px;
}

.ourMethodologycontent-section .content .button a {
	background-color: #3d3d3d;
	padding: 12px 40px;
	text-decoration: none;
	color: #fff;
	font-size: 25px;
	letter-spacing: 1.5px;
}

.ourMethodologycontent-section .content .button a:hover {
	background-color: #a52a2a;
	color: #fff;
}



@media screen and (max-width: 768px) {
	.ourMethodology {
		height: 230vh;
	}

	.image-section {
		display: none;
	}

	.containerOurMethodology {
		width: 100%;
		display: block;
		margin: auto;
		padding-top: 50px;
		text-align: left;
	}

	.titleOurMethodology h1 {
		font-size: 50px;
	}

	.ourMethodologycontent-section .content p {
		font-size: 1.3rem;
		width: 90%;
		text-align: left;
		margin: auto;
	}

	.ourMethodologycontent-section .content-point {
		margin-bottom: 180px;
		/* Add space between content points */
	}


	.ourMethodologycontent-section .content-point p {
		font-size: 15px;
		width: 100%;
		/* Enlarge the width of the content-point p */
		position: absolute;
	}

	.ourMethodologycontent-section .content-point:nth-child(3) p {
		width: 210%;
		/* Enlarge the width of the content-point p */
	}

	.ourMethodologycontent-section .content-point:nth-child(4) p {
		width: 120%;
		/* Enlarge the width of the content-point p */
	}


	.ourMethodologycontent-section .content-point h5 {
		font-size: 20px;
	}

	.ourMethodologycontent-section .content-point img {
		width: 30px;
		height: 30px;
	}

	.ourMethodologycontent-section {
		float: none;
		width: 100%;
		display: block;
		margin: auto;
	}

	.image-section {
		float: none;
		width: 100%;
	}

	.image-section .img-OurMethodology {
		width: 100%;
		height: auto;
		display: block;
		margin: auto;
	}

	.ourMethodologycontent-section .content .button {
		text-align: center;
	}

	.ourMethodologycontent-section .content .button a {
		padding: 9px 30px;
	}
}


@media (min-width: 769px) and (max-width: 992px) {
	.ourMethodology {
		height: 150vh;
		min-width: 196.5%;
	}

	.image-section {
		display: none;
	}

	.containerOurMethodology {
		width: 80%;
		display: block;
		margin: auto;
		padding-top: 50px;
	}

	.ourMethodologycontent-section .content p {
		font-size: 30px;
		width: 100%;
	}

	.ourMethodologycontent-section .content-point p {
		font-size: 25px;
	}

	.ourMethodologycontent-section .content-point h5 {
		font-size: 25px;
	}

	.ourMethodologycontent-section .content-point img {
		width: 50px;
		height: 50px;
	}

	.ourMethodologycontent-section {
		float: none;
		width: 100%;
		display: block;
		margin: auto;
	}

	.image-section {
		float: none;
		width: 100%;

	}

	.image-section .img-OurMethodology {
		width: 100%;
		height: auto;
		display: block;
		margin: auto;
	}

	.ourMethodologycontent-section .content .button {
		text-align: center;
	}

	.ourMethodologycontent-section .content .button a {
		padding: 9px 30px;
	}

}


@media (min-width: 1024px) and (max-width: 1366px) {
	.ourMethodology {
		width: 100%;
		min-height: 190vh;
	}
}