.aboutUs {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 133vh;
	background-color: #F2FBFF;
}

.section {
	text-align: center;
	margin-bottom: 20px;
}

.titleAboutUs {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #211b59;
}

.containerAboutUs {
	width: 80%;
	display: block;
	margin: auto;
	padding-top: 100px;
	margin-right: 100px;
}

.content-section {
	float: left;
	width: 55%;
	color: #211b59;
}

.pyramid-loader {
	position: relative;
	width: 400px;
	height: 300px;
	display: block;
	transform-style: preserve-3d;
	transform: rotateX(-20deg);
}

.wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	animation: spin 4s linear infinite;
}

@keyframes spin {
	100% {
		transform: rotateY(360deg);
	}
}

.pyramid-loader .wrapper .side {
	width: 180px;
	height: 180px;
	position: absolute;
	top: -200px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	transform-origin: center top;
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.pyramid-loader .wrapper .side1 {
	transform: rotateZ(-30deg) rotateY(90deg);
	background: conic-gradient(#0277b5, #211b59, #D8CCE6, #211b59);
}

.pyramid-loader .wrapper .side2 {
	transform: rotateZ(30deg) rotateY(90deg);
	background: conic-gradient(#211b59, #D8CCE6, #211b59, #0277b5);
}

.pyramid-loader .wrapper .side3 {
	transform: rotateX(30deg);
	background: conic-gradient(#211b59, #D8CCE6, #211b59, #0277b5);
}

.pyramid-loader .wrapper .side4 {
	transform: rotateX(-30deg);
	background: conic-gradient(#0277b5, #211b59, #D8CCE6, #211b59);
}

.pyramid-loader .wrapper .shadow {
	width: 130px;
	height: 130px;
	background: #211b59bc;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	transform: rotateX(90deg) translateZ(-40px);
	filter: blur(12px);
}

.pyramidImage-section {
	float: right;
	width: 40%;
}

.pyramidImage-section img {
	width: 100%;
	height: auto;
}

.content-section .title {
	font-size: 28px;
	color: #211b59;
	text-align: left;
}

.content-section .content h3 {
	margin-top: 20px;
	color: #5d5d5d;
	font-size: 21px;
}

.content-section .content p {
	margin-top: 10px;
	font-family: sans-serif;
	font-size: 18px;
	line-height: 1.5;
	text-align: justify;
}

.video-container {
	text-align: center;
	margin-top: 20px;
}

.video-container iframe {
	border-radius: 10px;
	border: none;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}



@media screen and (max-width: 768px) {
	.aboutUs {
		min-width: 100%;
        min-height: 150vh;
	}

	.titleAboutUs h1 {
		font-size: 3rem;
	}

	.containerAboutUs .content-section .content p {
		font-size: 1.3rem;
		width: 140%;
		text-align: justify; 
		margin-left: 27px;
	}

	.content-section {
		float: none;
		width: 100%;
		display: block;
		margin: auto;
	}

	.content-section .title {
		text-align: left;
		font-size: 28px;
		margin-left: 27px;
	}

	.pyramid-loader {
		display: none;
	}

	.pyramid-loader .wrapper .side {
		width: 8rem;
		height: 8rem;
		top: -320px;
	}

	.pyramid-loader .wrapper .shadow {
		width: 6rem;
		height: 6rem;
	}

	.video-container iframe {
		width: 100%;
		max-height: 150%;
	}
}


@media (min-width: 1024px) and (max-width: 1366px) {
	.aboutUs {
		width: 100%;
		height: 152vh;
	}
}


@media (min-width: 769px) and (max-width: 992px) {
	.aboutUs {
		height: 200vh;
		min-width: 196.5%;
	}

	.titleAboutUs h1 {
		font-size: 5rem;
	}

	.containerAboutUs .content-section .content p {
		font-size: 30px;
	}

	.containerAboutUs {
		width: 80%;
		display: block;
		margin: auto;
		padding-top: 50px;
	}

	.content-section {
		float: none;
		width: 100%;
		display: block;
		margin: auto;
	}

	.pyramidImage-section {
		float: none;
		width: 100%;

	}

	.pyramidImage-section .img-aboutus {
		width: 100%;
		height: auto;
		display: block;
		margin: auto;
	}

	.content-section .title {
		text-align: left;
		font-size: 35px;
	}

	.pyramid-loader {
		margin-top: 300px;
		margin-left: 25rem;
	}

	.pyramid-loader .wrapper .side {
		width: 250px;
		height: 250px;
		top: -320px;
	}

	.pyramid-loader .wrapper .shadow {
		width: 180px;
		height: 180px;
	}
}