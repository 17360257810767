.ContactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 120vh;
  background-color: #F2FBFF;
}

.ContactUs h1 {
  text-align: center;
  color: #211b59;
  margin-bottom: 2rem;
}

.ContactUsform {
  max-width: 420px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
}

.modal-header {
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

.modal-header .title,
.modal-header .description {
  font-size: 18px;
}

.modal-content h2 {
  margin-top: 0;
}

.ContactUsform-group {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}


.ContactUsform-group .ContactUsbutton-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactUsname-inputs {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.ContactUsinput {
  width: 100%;
  height: 45px;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
}

.ContactUsinput[type="email"],
.ContactUsinput[type="tel"] {
  width: 100%;
}

.ContactUsinput:hover {
  border: 2px solid lightgrey;
  box-shadow: 0px 0px 20px -17px;
}

.ContactUsinput:active {
  transform: scale(0.95);
}

.ContactUsinput:focus {
  border: 2px solid #211b59;
}

.ContactUstextarea {
  width: 100%;
  max-width: 450px;
  height: 100px;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
}

.ContactUstextarea:hover {
  border: 2px solid lightgrey;
  box-shadow: 0px 0px 20px -17px;
}

.ContactUstextarea:active {
  transform: scale(0.95);
}

.ContactUstextarea:focus {
  border: 2px solid #211b59;
}

.ContactUscheckbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ContactUscheckbox-label input[type="checkbox"] {
  margin-right: 8px;
}


@media (max-width: 768px) {

  .ContactUsform {
    max-width: 220px;
    width: 70%;
    padding: 5px;
  }

  .ContactUs {
    min-width: 100%;
  }

  .modal-header {
    display: none;

  }


  .modal-header .title,
  .modal-header .description {
    display: none;
  }
}


@media (min-width: 769px) and (max-width: 992px) {
  .ContactUs {
    min-width: 200%;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .ContactUs {
    width: 100%;
    min-height: 100vh;
  }
}