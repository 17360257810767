.appointment {
    width: 100%;
    height: 200vh;
    display: flex;
    background-color: #F2FBFF;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 20px;
    overflow-x: hidden;
}

.appointment .calendly-inline-widget {
    width: 100rem;
    height: 70rem;
}

.schedulingAppointment {
    margin: 0 auto;
    width: 100rem;
    margin-top: 20px;
    margin-left: -3%;
}

.appointment-details {
    margin-top: 20px;
    color: #211B59;
    text-align: center;
    margin: 0 auto;

}

.appointment-details h1 {
    width: 100%;
}

.appointment-details p {
    padding: 10px;
    text-align: center;
}

.appointment-details .contact-info {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 712px) {
    .appointment {
        height: 300vh;
        min-width: 100%;
    }

    .schedulingAppointment {
        margin-left: 10%;
        margin-top: 50rem;
    }

    .appointment-details {
        margin: 0 auto;
    }

    .appointment-details p {
        font-size: 1.6rem;
    }

    .appointment-details .contact-info {
        font-size: 1.3rem;
    }


    .appointment-details+.schedulingAppointment {
        margin-top: 20px;
    }


    .appointment .calendly-inline-widget {
        width: 22rem;
        height: 70rem;
    }


}


@media (min-width: 714px) and (max-width: 992px) {
    .appointment {
        height: 250vh;
        min-width: 196.5%;
    }

    .schedulingAppointment {
        margin-left: 10%;
        margin-top: 50rem;
    }

    .appointment-details {
        margin: 0 auto;
    }

    .appointment-details p {
        font-size: 1.6rem;
    }

    .appointment-details .contact-info {
        font-size: 1.3rem;
    }


    .appointment-details+.schedulingAppointment {
        margin-top: 20px;
    }


    .appointment .calendly-inline-widget {
        width: 100rem;
        height: 70rem;
    }

    .schedulingAppointment {
        margin: 0 auto;
        width: 100rem;
        margin-top: 20px;
    }

}

@media (min-width: 1024px) and (max-width: 1366px) {
    .appointment {
        width: 100%;
        height: 250vh;
    }

    .schedulingAppointment {
        margin: 0 auto;
        width: 100rem;
        margin-top: 20px;
        margin-left: -10%;
    }

}