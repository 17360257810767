.ProfilesSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 129vh;
  background-color: #F2FBFF;
  flex-direction: column;
}

.Title h1{
  color: #211b59;
  text-align: center;
  margin: 20px 0;
}

.Title p{
  color: #211b59;
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
}

.ProfileCardsContainer {
  display: flex;
  gap: 100px;
}

.ProfileCard {
  width: 300px;
  height: 450px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.ProfileImage {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.ProfileInfo {
  padding: 20px;
  text-align: center;
}

.ProfileInfo p {
  color: #211b59;
}

.ProfileInfo h3 {
  color: #211b59;
}

.ProfileLink {
  color: #0277b5;
  font-size: 24px;
}

@media (max-width: 768px) {
  .ProfilesSection {
    min-height: 270vh;
  }

  .ProfileCardsContainer {
    flex-direction: column;
  }

  .ProfileCard {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  .Title h1 {
		font-size: 3rem;
  }

  .Title p{
    color: #211b59;
    text-align: center;
    margin: 20px 0;
		font-size: 1.3rem;
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  .ProfilesSection {
    width: 100%;
    min-height: 150vh;
  }
}