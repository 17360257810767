.newsletter {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #211b59;
    background-color: #F2FBFF;
}

.newsletter-title {
    font-size: 50px;
}

.newsletter-input {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.input-newsletter {
    flex: 1;
    max-width: 300px;
    padding: 20px;
    font-size: 17px;
    color: black;
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
    border: 1px solid #211b5933;
    background-color: #C6EEFF;
}

.button-newsletter {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 5px 15px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 17px;
    border-radius: 0 .5em .5em 0;
    overflow: hidden;
    background: #211B59;
    color: ghostwhite;
}

.button-newsletter span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
}

.button-newsletter:hover span {
    color: #fff;
    z-index: 11;
}

.button-newsletter::before,
.button-newsletter::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.button-newsletter::before {
    content: "";
    background: #0277B5;
    width: 135%;
    left: -20%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.button-newsletter:hover::before {
    transform: translate3d(100%, 0, 0);
}


.social-media-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-media-icons a {
    margin: 0 0.5rem;
    font-size: 2.2rem;
    transition: color 0.3s ease;
}

.social-media-icons a:hover {
    color: #ff0000;
}

.social-media-icons a:nth-child(1) {
    color: #3b5998;
}

.social-media-icons a:nth-child(2) {
    color: #0077b5;
}

.social-media-icons a:nth-child(3) {
    color: #c13584;
}

.social-media-icons a:nth-child(4) {
    color: #ff0000;
}


@media (max-width: 768px) {
    .newsletter {
        min-width: 100%;
    }

    .newsletter-title {
        font-size: 25px;
        text-align: center;
    }

    .newsletter-input {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .input-newsletter {
        flex: 1;
        max-width: 180px;
        padding: 20px;
        font-size: 17px;
        color: black;
        border-top-left-radius: .5em;
        border-bottom-left-radius: .5em;
        border: 1px solid #211b5933;
        background-color: #C6EEFF;
    }
    

}


@media (min-width: 769px) and (max-width: 992px) {
    .newsletter {
        min-width: 200%;
    }

    .newsletter-title {
        font-size: 40px;
    }

    .newsletter-input {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


@media (min-width: 1024px) and (max-width: 1366px) {
	.newsletter {
	  width: 100%;
	  min-height: 200vh;
	}
  }