.Bdp {
  width: 100%;
  height: 100vh;
  background-color: #F2FBFF;
}

.content-Bdp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.centered-content {
  text-align: center;
  margin-bottom: 5rem;
}

.title-Bdp {
  font-size: 2.8rem;
  margin-bottom: 1rem;
  color: #211B59;
}

.text-Bdp {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: #211B59;
  width: 100%;
}

.countdown {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #0277B5;
}

.prices-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.prices {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.old-price {
  font-size: 1.2rem;
  color: #888;
  margin-right: 0.5rem;
}

.strikethrough {
  text-decoration: line-through;
  color: #888;
}

.new-price {
  font-size: 2rem;
  font-weight: bold;
  color: #0277B5;
}

.timer-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0277B5;
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
}

.timer-item {
  text-align: center;
  margin: 0 0.5rem;
}

.timer-item span:first-child {
  font-size: 2rem;
  font-weight: bold;
}

.timer-item span:last-child {
  font-size: 1rem;
  opacity: 0.8;
}


@media (max-width: 768px) {
  .Bdp {
    min-width: 100%;
    height: 120vh;
  }

  .title-Bdp {
    font-size: 2rem;
  }

  .text-Bdp {
    font-size: 1.5rem;
  }

  .timer {
    padding: 0.7rem; /* Adjust padding for smaller screens */
  }

  .timer-item {
    margin: 0 0.3rem; /* Adjust margin for smaller screens */
  }

  .timer-item span:first-child {
    font-size: 1.5rem; /* Reduce font size for smaller screens */
  }

  .timer-item span:last-child {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
  }
}


@media (min-width: 769px) and (max-width: 992px) {
  .Bdp {
    min-width: 196.5%;
    height: 120vh;
  }

  .title-Bdp {
    font-size: 3rem;
  }

  .text-Bdp {
    font-size: 1.5rem;
  }
}


@media (min-width: 1024px) and (max-width: 1366px) {
  .Bdp {
    width: 100%;
    min-height: 100vh;
  }
}