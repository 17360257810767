.Lessons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: #F2FBFF;
}

.contentLessons {
    margin-top: 180px;
}

.Lessons h1 {
    text-align: center;
    color: #211b59;
    margin-bottom: 2rem;
}

#container {
    background: rgb(222, 245, 255);
    text-align: center;
    border-radius: 80px;
    overflow: hidden;
    height: 350px;
    width: 70rem;
    margin-top: 70px;
}

.product-details {
    position: relative;
    text-align: left;
    overflow: hidden;
    padding: 30px;
    height: 100%;
    float: left;
    width: 65%;
    margin: 0 auto;
    margin-top: 20px;
}

#container .product-details h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 30px;
    color: #211b59;
    margin: 0;
    margin-bottom: 10px;
}


#container .product-details>p {
    text-align: center;
    font-size: 22px;
    color: #211b59;
    text-align: justify;

}

.product-image {
    transition: all 0.3s ease-out;
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 33%;
    display: inline-block;
    margin: 0 auto;
    left: 40px;
}

#container img {
    width: 100%;
    height: 100%;
}

.button-containerLessons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.button-containerLessons .open-enrollment-link {
    color: #0277b5;
    margin-top: 10px;
    cursor: pointer;
}

.price {
    margin-left: 5px;
    border: 2px solid #211b59;
    color: #211b59;
    padding: 5px;
    border-radius: 15px;
    margin-left: 10px;
}

.pricePromotion{
    margin-left: 5px;
    border: 2px solid #211b59;
    color: #211b59;
    padding: 5px;
    border-radius: 15px;
    margin-left: 10px;
}

.ctaLessons {
    border: none;
    background: none;
    margin-bottom: 10px;
    /* Added margin-bottom */
}

.inscription-info {
    margin-top: 10px;
    font-size: 16px;
    color: #211b59;
    text-align: center;
}


.ctaLessons span {
    padding-bottom: 7px;
    font-size: 14px;
    padding-right: 15px;
    text-transform: uppercase;
    font-family: 'Franklin Gothic Medium';
}

.ctaLessons svg {
    transform: translateX(-8px);
    transition: all 0.3s ease;
}

.ctaLessons:hover svg {
    transform: translateX(0);
}

.ctaLessons:active svg {
    transform: scale(0.9);
}

.hover-underline-animation {
    position: relative;
    color: #211b59;
    padding-bottom: 20px;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #211b59;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.ctaLessons:hover .hover-underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


@media (max-width: 768px) {
    .Lessons {
        min-width: 100%;
    }

    #container {
        width: 100%;
        margin-top: 20px;
        height: 100%;
    }

    #container .product-details h1 {
        font-size: 30px;
    }

    #container .product-details>p {
        font-size: 25px;
    }

    .ctaLessons {
        font-size: 23px;
    }

    .ctaLessons span {
        font-size: 15px;
    }

    .ctaLessons svg {
        font-size: 15px;
    }

    .button-containerLessons a {
        font-size: 15px;
    }

    .product-details {
        width: 100%;
    }

    .product-image {
        width: 100%;
        left: 0;
    }

    .price {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

}


@media (min-width: 1024px) and (max-width: 1366px) {
    .Lessons {
        width: 100%;
        min-height: 100vh;
    }
}


@media (min-width: 769px) and (max-width: 992px) {
    .Lessons {
        min-width: 200%;
        flex-direction: row;
        min-height: 250vh;
    }

    #container {
        width: 100%;
        margin-top: 20px;
        height: 100%;
    }

    #container .product-details>p {
        font-size: 25px;
    }

    .ctaLessons {
        font-size: 23px;
    }

    .ctaLessons span {
        font-size: 23px;
    }

    .button-containerLessons a {
        font-size: 25px;
    }

    .product-details {
        width: 100%;
    }

    .product-image {
        width: 100%;
        left: 0;
    }
}