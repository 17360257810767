.discord {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 130vh;
    padding: 0 2rem;
    background-color: #F2FBFF;

}

.discord-content {
    max-width: 50%;
    color: #211b59;
    margin-right: 100px;
    height: 400px;
}

.discord-content p {
    width: 650px;
    font-size: 20px;
    text-align: left;
}


.discord-content .content-point {
    display: flex;
    margin-top: 20px;
}


.discord-content .content-point h5 {
    margin-left: 10px;
    font-size: 17px;
    margin-top: 5px;
}




.light-button button.bt {
    position: relative;
    height: 200px;
    display: flex;
    align-items: flex-end;
    outline: none;
    background: none;
    border: none;
    margin-right: 17rem;
    margin-bottom: 5rem;
}

.light-button button.bt .button-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    background-color: #211b59;
    border-radius: 5px;
    color: #211b59;
    font-weight: 700;
    transition: 300ms;
    outline: #211b59 2px solid;
    outline-offset: 20;
}

.light-button button.bt .button-holder svg {
    height: 50px;
    fill: #211b59;
    transition: 300ms;
}

.light-button button.bt .light-holder {
    position: absolute;
    height: 200px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.light-button button.bt .light-holder .dot {
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    background-color: #211b59;
    border-radius: 10px;
    z-index: 2;
}

.light-button button.bt .light-holder .light {
    position: absolute;
    top: 0;
    width: 200px;
    height: 200px;
    clip-path: polygon(50% 0%, 25% 100%, 75% 100%);
    background: transparent;
    transition: 300ms;
}

.light-button.on button.bt .button-holder svg {
    fill: rgba(88, 101, 242, 1);
}

.light-button.on button.bt .button-holder {
    color: rgba(88, 101, 242, 1);
    outline: rgba(88, 101, 242, 1) 2px solid;
    outline-offset: 2px;
}

.light-button.on button.bt .light-holder .light {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(88, 101, 242, 1) 0%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%);
}




@media (max-width: 768px) {
    .discord {
        padding: 0 1rem;
        height: auto;
        flex-direction: column;
        align-items: center;
        min-height: 200vh;
        min-width: 100%;
    }

    .discord-content {
        max-width: 50%;
        margin-right: 0;
        height: auto;
        margin-bottom: 15rem;
    }

    .discord-content h1{
        font-size: 35px;
    }

    .discord-content p {
        width: 100%;
        font-size: 20px;
    }

    .discord-content .content-point h5 {
        font-size: 20px;
    }

    .discord-content .content-point img {
        width: 40px;
        height: 40px;
    }

    .light-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        position: absolute;
    }

    .light-button button.bt {
        margin-right: 0;
        margin-bottom: 0;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .discord {
        padding: 0 1rem;
        height: auto;
        flex-direction: column;
        align-items: center;
        height: 80vh;
        min-width: 196.5%;
    }

    .discord-content {
        max-width: 80%;
        margin-right: 0;
        height: auto;
        margin-bottom: 15rem;
    }

    .discord-content p {
        width: 100%;
        font-size: 30px;
    }

    .discord-content .content-point h5 {
        font-size: 28px;
    }

    .discord-content .content-point img {
        width: 50px;
        height: 50px;
    }

    .light-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
        position: absolute;
    }

    .light-button button.bt {
        margin-right: 0;
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) {
	.discord {
	  width: 100%;
	  min-height: 100vh;
	}
  }


