.FormRamadanPromo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160vh;
    background-image: url('../../assets/bg-b2a-ramdanForm.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
}

