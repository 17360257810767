.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
}

.modal-content {
    background: rgb(222, 245, 255);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 650px;
    width: 90%;
    position: relative;
    text-align: center;
}

.modal-content h2 {
    margin-top: 0;
}

.form-group {
    margin-bottom: 10px;
}

.form-group.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


.name-inputs {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.input, .textarea{
    width: 100%;
    max-width: 450px; 
    height: 100px; 
    padding: 12px;
    border-radius: 12px;
    border: 1.5px solid lightgrey;
    outline: none;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0px 0px 20px -18px;
}

.selectinput{
    width: 100%;
    max-width: 450px; 
    height: 50px; 
    padding: 12px;
    border-radius: 12px;
    border: 1.5px solid lightgrey;
    outline: none;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0px 0px 20px -18px;
}


.input[type="email"],
.input[type="tel"] {
    height: 45px; 
}

.input:hover, .textarea:hover, .selectinput:hover{
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
}

.input:active, .textarea:active, .selectinput:active{
    transform: scale(0.95);
}

.input:focus, .textarea:focus, .selectinput:focus{
    border: 2px solid #211b59;
}


.terms {
    color: #0277b5;
}

.modal-overlay-btn {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 7px 30px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 17px;
    border-radius: 5px;
    overflow: hidden;
    background: #211b59;
    color: ghostwhite;
}

.modal-overlay-btn span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
}

.modal-overlay-btn:hover span {
    color: #fff;
    z-index: 11;
}

.modal-overlay-btn::before,
.modal-overlay-btn::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.modal-overlay-btn::before {
    content: "";
    background: #0277b5;
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.modal-overlay-btn:hover::before {
    transform: translate3d(100%, 0, 0);
}

.modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.modal-header h2 {
    margin-bottom: 10px;
    font-size: 2.2rem;
    color: #211b59;
}

.modal-header p {
    margin-bottom: 0;
    font-size: 1.09rem;
    color: #211b59;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #0277b5;
}

.close-icon:hover {
    color: #211b59;
}

.error-message {
    font-size: 14px;
    color: red;
}

.checkbox-label {
    display: flex; 
    align-items: center; 
    margin-bottom: 8px;
}


@media (max-width: 600px) {
    .modal-content {
        max-width: 90%;
    }
}