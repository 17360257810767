.number-counting-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    gap: 100px;
    background-color: #F2FBFF;
}

.counting-item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.counting-item h2 {
    font-weight: bold;
    color: #211b59;
    margin-right: 10px;
    font-size: 45px;
}

.counting-item h3 {
    font-size: 45px;
    font-weight: bold;
    color: #211b59;
    margin-right: 10px;

}


.counting-item p {
    font-size: 22px;
    text-align: left;
    margin: 0;
    color: #211b59ba;
    font-weight: 600;
}



.Brochure {
    width: 100%;
    height: 100vh;
    background-color: #F2FBFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #211b59;
}

.Brochure-title {
    font-size: 30;
}

.Brochure-text {
    text-align: center;
}

.Brochure-input {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.input-Brochure {
    flex: 1;
    max-width: 300px;
    padding: 20px;
    font-size: 17px;
    color: black;
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
    border: 1px solid #211b5933;
    background-color: #C6EEFF;
}


.button-Brochure {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 20px 15px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 17px;
    border-radius: 0 .5em .5em 0;
    overflow: hidden;
    background: #211B59;
    color: ghostwhite;
}

.button-Brochure span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
}

.button-Brochure:hover span {
    color: #fff;
    z-index: 11;
}

.button-Brochure::before,
.button-Brochure::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.button-Brochure::before {
    content: "";
    background: #0277B5;
    width: 135%;
    left: -20%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.button-Brochure:hover::before {
    transform: translate3d(100%, 0, 0);
}

.checkbox-label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}




@media (max-width: 768px) {
    .number-counting-section {
        min-width: 100%;
        flex-direction: column;
    }

    .counting-item h2 {
        font-size: 2.5rem;
    }

    .counting-item h3 {
        font-size: 2.5rem;
    }

    .counting-item p {
        font-size: 1.5rem;
    }

    .counting-item img {
        width: 120px;
        height: 120px;
    }

    .Brochure-title {
        font-size: 35px;
        text-align: center;
    }

    .Brochure-text {
        font-size: 1.4rem;
    }

    .Brochure-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
    }

    .input-Brochure,
    .button-Brochure,
    .checkbox-label {
        flex: 1;
        max-width: calc(100% - 10px);
        padding: 10px;
        font-size: 12px;
    }

    .input-Brochure {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        border: 1px solid #211b5933;
        background-color: #C6EEFF;
    }

    .button-Brochure {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border: none;
        background: #211B59;
        color: ghostwhite;
    }

    .checkbox-label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        /* Adjust gap */
        margin-top: 10px;
        width: 100%;
        /* Occupy full width */
    }

    .error-message {
        text-align: center;
    }

}


@media (min-width: 769px) and (max-width: 992px) {
    .number-counting-section {
        min-width: 196.5%;
        flex-direction: column;
    }

    .counting-item h2 {
        font-size: 4rem;
    }

    .counting-item h3 {
        font-size: 4rem;

    }

    .counting-item p {
        font-size: 2rem;
    }

    .counting-item img {
        width: 150px;
        height: 150px;
    }

    .Brochure-title {
        font-size: 45px;
    }

    .Brochure-text {
        font-size: 1.4rem;
    }

    .Brochure {
        min-width: 196.5%;
    }
}


@media (min-width: 1024px) and (max-width: 1366px) {
    .number-counting-section {
        width: 100%;
        min-height: 100vh;
    }

    .Brochure {
        width: 100%;
        min-height: 100vh;
    }
}