.RoadMap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #211b59;
    width: 100%;
    min-height: 100vh;
    background-color: #F2FBFF;
}

.RoadMap h3,
.RoadMap h1 {
    text-align: center;
}

.process-wrapper {
    margin: auto;
    max-width: 1080px;
}

#progress-bar-container {
    position: relative;
    width: 200%;
    margin: auto;
    height: 100px;
    margin-top: 150px;
    margin-left: -230px;
}

#progress-bar-container ul {
    padding: 0;
    margin: 0;
    padding-top: 15px;
    z-index: 9999;
    position: absolute;
    width: 100%;
    margin-top: -40px;
}

#progress-bar-container li:before {
    content: " ";
    display: block;
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 2px #aaa;
    transition: all ease 0.3s;
}

#progress-bar-container li.active:before,
#progress-bar-container li:hover:before {
    border: solid 2px #fff;
    background: linear-gradient(to right, #0277b5 0%, #fff 100%);
}

#progress-bar-container li {
    list-style: none;
    float: left;
    width: 25%;
    text-align: center;
    color: #aaa;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    font-weight: 700;
    transition: all ease 0.2s;
    vertical-align: bottom;
    height: 60px;
    position: relative;
}

#progress-bar-container li .step-inner {
    position: absolute;
    width: 100%;
    bottom: 0;
    font-size: 14px;
}

#progress-bar-container li.active,
#progress-bar-container li:hover {
    color: #444;
}

#progress-bar-container li:after {
    content: " ";
    display: block;
    width: 6px;
    height: 6px;
    background: #777;
    margin: auto;
    border: solid 7px #fff;
    border-radius: 50%;
    margin-top: 40px;
    box-shadow: 0 2px 13px -1px rgba(0, 0, 0, 0.3);
    transition: all ease 0.2s;
}

#progress-bar-container li:hover:after {
    background: #555;
}

#progress-bar-container li.active:after {
    background: #207893;
}

#progress-bar-container #line {
    width: 80%;
    margin: auto;
    background: #eee;
    height: 6px;
    position: absolute;
    left: 10%;
    top: 57px;
    z-index: 1;
    border-radius: 50px;
    transition: all ease 0.9s;
}

#progress-bar-container #line-progress {
    content: " ";
    width: 0;
    height: 100%;
    background: #0277b5;
    background: linear-gradient(to right, #211b59 0%, #211b59 100%);
    position: absolute;
    z-index: 2;
    border-radius: 50px;
    transition: all ease 0.9s;
}


#progress-content-section {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 600px; /* Set your desired fixed width here */
    max-width: calc(100% - 40px); /* Adjust the maximum width based on container */
    margin-top: 20px; /* Adjust margin-top as per your design */
    background: #0277b5;
    border-radius: 4px;
    overflow: hidden;
}


#progress-content-section .section-content {
    padding: 30px 40px;
    text-align: center;
}

#progress-content-section .section-content h2 {
    font-size: 17px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 1px;
}

#progress-content-section .section-content p {
    font-size: 20px;
    line-height: 1.8em;
    color: #fff;
}



#progress-content-section .section-content {
    display: none;
    animation: FadeInUp 700ms ease 1;
    animation-fill-mode: forwards;
    transform: translateY(15px);
    opacity: 0;
}

#progress-content-section .section-content.active {
    display: block;
}

@keyframes FadeInUp {
    0% {
        transform: translateY(15px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}


@media (max-width: 768px) {
    .RoadMap {
        min-width: 100%;
        min-height: 150vh;
    }

    #progress-bar-container {
        width: 200%;
        max-width: 350px;
        margin: 150px auto 0;
        left: -40%;
        right: 0;
    }


    #progress-content-section {
        width: 70%;
        margin: auto;
        background: #0277b5;
        border-radius: 4px;
        overflow: hidden;
    }

    #progress-content-section .section-content p {
        font-size: 0.8rem;
    }

    .process-wrapper h1 {
        font-size: 25px;
    }

    .process-wrapper h3 {
        font-size: 20px;
    }
}


@media (min-width: 1024px) and (max-width: 1366px) {
    .RoadMap {
        width: 100%;
        min-height: 100vh;
    }
}


@media (min-width: 769px) and (max-width: 992px) {

    .RoadMap {
        min-width: 100%;
    }

    #progress-bar-container {
        width: 100%;
        margin-left: 0;
    }

    #progress-content-section .section-content p {
        font-size: 30px;
    }

    .process-wrapper h3 {
        font-size: 35px;
    }
}