.ProgramModulesAi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
  background-color: #F2FBFF;
  position: relative;
}

.ProgramModulesAiImage {
  margin-left: 150px;
  margin-top: -50px;
  animation: floatAnimation 2s ease-in-out infinite 3.5s;
}

.ProgramModulesAiImageRight {
  position: absolute;
  margin-left: 70rem;
  margin-top: -25.5rem;
  animation: floatAnimation 2s ease-in-out infinite 2.5s;

}

.ProgramModulesAilignes {
  margin-left: -200px;
  margin-top: -4rem;
  position: absolute;
}



#rocket-icon {
  width: 140px;
  transform: rotate(-10deg);
  margin-top: 165px;
  margin-left: 415px;
}

.ProgramModulesAicontentModules {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: -22rem;
  width: 300px;
  margin-left: 210px;
}

.ProgramModulesAicontentModules h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #0580C2;

}

.ProgramModulesAicontentModules p {
  font-size: 15px;
  color: #211B59;
}

.ProgramModulesAicontentModulesTwo {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 8.5rem;
  width: 300px;
  margin-left: 31rem;
}

.ProgramModulesAicontentModulesTwo h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #0580C2;
}

.ProgramModulesAicontentModulesTwo p {
  font-size: 15px;
  color: #211B59;
}


.ProgramModulesAicontentModulesThree {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: -35rem;
  width: 350px;
  margin-left: 42rem;
}

.ProgramModulesAicontentModulesThree h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #0580C2;

}

.ProgramModulesAicontentModulesThree p {
  font-size: 15px;
  color: #211B59;
}



.ProgramModulesAicontentModulesFourth {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 9rem;
  width: 350px;
  margin-left: 53rem;
}

.ProgramModulesAicontentModulesFourth h1 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #0580C2;
}

.ProgramModulesAicontentModulesFourth p {
  font-size: 15px;
  color: #211B59;
}



@media (max-width: 768px) {
  .ProgramModulesAi {
    min-width: 100%;
    }

  .ProgramModulesAiImage {
    margin-left: 10px;
    margin-top: -30px;
    width: 250px;
    height: 250px;
  }

  .ProgramModulesAiImageRight {
    display: none;
  }

  .ProgramModulesAilignes {
    display: none;
  }

  #rocket-icon {
    display: none;
  }

  .ProgramModulesAicontentModules,
  .ProgramModulesAicontentModulesTwo,
  .ProgramModulesAicontentModulesThree,
  .ProgramModulesAicontentModulesFourth,
  .ProgramModulesAicontentModulesFive,
  .ProgramModulesAicontentModulessix {
    width: 70%;
    margin: 1rem auto;
    z-index: 10;
  }

  .ProgramModulesAi h1 {
    font-size: 21px;
    width: 100%;
  }


  .ProgramModulesAi p {
    font-size: 19px;
    color: #211B59;
  }


  .ProgramModulesAi::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: #0580C2;
    border-radius: 1px;
    z-index: -1;
  }

  .ProgramModulesAi::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 70%;
    background-color: #0580C2;
    border-radius: 1px;
    z-index: 0;
    margin-top: 15rem;
  }

  .ProgramModulesAi::before {
    background-image: linear-gradient(to bottom, transparent 50%, #ccc 50%);
    background-size: 100% 20px;
  }
}


@media screen and (max-width: 1366px) and (min-width: 769px) {
  .ProgramModulesAiImage {
    margin-left: 20px;
  }

  .ProgramModulesAiImageRight {
    margin-left: 59.5rem;
    margin-top: -26.5rem;
  }

  .ProgramModulesAilignes {
    margin-left: -350px;
  }

  #rocket-icon {
    margin-left: 16rem;
  }

  .ProgramModulesAicontentModules {
    margin-left: 50px;
  }

  .ProgramModulesAicontentModulesTwo {
    margin-left: 20rem;
  }

  .ProgramModulesAicontentModulesThree {
    margin-left: 34rem;
  }

  .ProgramModulesAicontentModulesFourth {
    margin-left: 41rem;
  }

  .ProgramModulesAicontentModulesFive {
    margin-left: 54rem;
  }

  .ProgramModulesAicontentModulessix {
    margin-left: 61rem;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .ProgramModulesAi {
    min-width: 196.5%;
  }

  .ProgramModulesAiImage {
    margin-left: 10px;
    margin-top: -30px;
    width: 250px;
    height: 250px;
  }

  .ProgramModulesAiImageRight {
    display: none;
  }

  .ProgramModulesAilignes {
    display: none;
  }

  #rocket-icon {
    display: none;
  }

  .ProgramModulesAicontentModules,
  .ProgramModulesAicontentModulesTwo,
  .ProgramModulesAicontentModulesThree,
  .ProgramModulesAicontentModulesFourth,
  .ProgramModulesAicontentModulesFive,
  .ProgramModulesAicontentModulessix {
    width: 70%;
    margin: 1rem auto;
    z-index: 10;
  }

  .ProgramModulesAi h1 {
    font-size: 30px;
    width: 110%;
  }


  .ProgramModulesAi p {
    font-size: 25px;
    color: #211B59;
  }


  .ProgramModulesAi::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: #0580C2;
    border-radius: 1px;
    z-index: -1;
  }

  .ProgramModulesAi::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 70%;
    background-color: #0580C2;
    border-radius: 1px;
    z-index: 0;
    margin-top: 15rem;
  }

  .ProgramModulesAi::before {
    background-image: linear-gradient(to bottom, transparent 50%, #ccc 50%);
    background-size: 100% 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .ProgramModulesAi {
    width: 100%;
    min-height: 100vh;
  }
}