.Bdp {
    width: 100%;
    height: 100vh;
    background-color: #F2FBFF;
  }
  
  .content-Bdp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .centered-content {
    text-align: center;
    margin-bottom: 5rem;
  }
  
  .title-Bdp {
    font-size: 2.8rem;
    margin-bottom: 1rem;
    color: #211B59;
  }
  
  .text-Bdp {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: #211B59;
    max-width: 600px; /* Adjust the value as needed */
    margin: 0 auto; /* Center the element horizontally */
    text-align: center;
  }
  
  
  
  .centered-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: #0277B5;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .Bdp {
      min-width: 100%;
      height: 120vh;
    }
  
    .title-Bdp {
      font-size: 2rem;
    }
  
    .text-Bdp {
      font-size: 1.5rem;
    }
  }
  
  
  @media (min-width: 769px) and (max-width: 992px) {
    .Bdp {
      min-width: 196.5%;
      height: 120vh;
    }
  
    .title-Bdp {
      font-size: 3rem;
    }
  
    .text-Bdp {
      font-size: 1.5rem;
    }
  }
  
  
  @media (min-width: 1024px) and (max-width: 1366px) {
    .Bdp {
      width: 100%;
      min-height: 100vh;
    }
  }