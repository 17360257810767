.navbar {
    width: 100%;
    height: 100px;
    z-index: 15;
    position: fixed;
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .logo {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    margin: 1rem;
    color: #f6f6f6;
    z-index: 5;
    margin-top: -8px;
}


.logo-img {
    width: 150%;
    height: auto;
    cursor: pointer;
}

.hamburger {
    display: none;
    padding: 1rem;
}

.mobile-menu {
    display: none;
}

.nav-menu {
    display: flex;
    margin-top: -25px;
}

.nav-menu li {
    padding: 2rem;
    position: relative;
}

.animated-ligne::after {
    content: "";
    position: absolute;
    bottom: 25px;
    left: 24px;
    border-radius: 10px;
    width: 0;
    height: 3px;
    background-color: #211B59;
    transition: width 0.3s ease-in-out;
}

.animated-ligne:hover::after {
    width: calc(100% - 3rem);
}

.nav-menu li a {
    color: #211B59;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#nav-menu-btn {
    outline: none;
    cursor: pointer;
    border: none;
    padding: 5px 15px;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    position: relative;
    display: inline-block;
    letter-spacing: 0.05rem;
    font-weight: 700;
    font-size: 17px;
    border-radius: 5px;
    overflow: hidden;
    background: #211B59;
    color: ghostwhite;
}

#nav-menu-btn span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
}

#nav-menu-btn:hover span {
    color: #fff;
    z-index: 11;
}

#nav-menu-btn::before,
#nav-menu-btn::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

#nav-menu-btn::before {
    content: "";
    background: #0277B5;
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

#nav-menu-btn:hover::before {
    transform: translate3d(100%, 0, 0);
}


.language-dropdown {
    position: relative;
    display: inline-block;
}

.selected-language {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #211B59;
}

.selected-language span {
    margin-right: 5px;
    color: #211B59;
}

.language-options {
    position: absolute;
    top: 100%;
    left: -25px;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    display: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    overflow: hidden;
    max-height: 200px;
    overflow-y: auto;
    color: #0277B5;
    width: 100px;
}

.language-options li {
    padding: 5px 10px;
    cursor: pointer;
}

.language-options li:hover {
    background-color: #f2f2f2;
}

.language-dropdown:hover .language-options {
    display: block;
}



.navbar.scrolled {
    background-color: rgb(222, 245, 255);
}








@media (max-width: 768px) {
    .navbar {
        position: fixed;
        height: 200px;
        z-index: 15;
    }

    .hamburger {
        display: block;
        z-index: 5;
        transform: scale(0.7);
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        height: 60rem;
        background-color: #5ba7d0;
        top: 0;
        right: -100%;
        transition: 0.9s ease-in-out;
    }

    .navbar .logo {
        width: 120px;
        margin-top: 10px;
    }


    .selected-language {
        color: #fff;
    }

    .selected-language span {
        color: #fff;
        font-size: 1rem;
    }

    .selected-language svg {
        font-size: 0.8rem;
    }

    .active {
        right: 0;
    }

    .nav-menu li {
        margin: .1rem;
        padding: 1rem;

    }

    .animated-ligne::after {
        bottom: 8px;
        left: 10px;
    }

    .animated-ligne:hover::after {
        width: calc(100% - 2rem);
    }

    .nav-menu li a {
        font-size: 1.2rem;
        color: #fff;
    }


    #nav-menu-btn {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.1rem;
    }

    .nav-menu .social-icons {
        display: flex;
        justify-content: space-evenly;
        margin: 1rem;
        margin-top: 5rem;
    }

    .nav-menu .icon {
        color: #fff;
        font-size: 1.3rem;
    }

    .nav-menu .icon:hover {
        color: #211B59;
    }

    .hide {
        display: none;
    }

    .navbar .slide-right {
        margin-left: 35%;
        transition: .8s ease-in-out;
    }


    .language-options {
        width: 130px;
        margin-left: 15px;
        z-index: 15;
    }

    .language-options li {
        padding: 1px 8px;
        font-size: 1.1rem;
        cursor: pointer;
    }

    .navbar.scrolled {
        background-color: rgb(222, 245, 255);
        height: 150px;
    }

}


@media (min-width: 1024px) and (max-width: 1366px) {
    .programs {
        width: 100%;
        min-height: 100vh;
    }
}


@media (min-width: 769px) and (max-width: 992px) {
    .navbar {
        position: fixed;
        height: 200px;
        z-index: 15;
    }

    .mobile-menu button#nav-menu-btn {
        width: 90%;
        display: block;
        margin: 0 auto;
        top: 50px;
    }

    .hamburger {
        display: block;
        z-index: 5;
    }

    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 5rem;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        height: 90rem;
        background-color: #5ba7d0;
        top: 0;
        right: -100%;
        transition: 0.9s ease-in-out;
    }

    .navbar .logo {
        width: 130px;
        height: 130px;
        margin-top: 10px;
    }


    .selected-language {
        color: #fff;
    }

    .selected-language span {
        color: #fff;
        font-size: 2rem;
    }

    .selected-language svg {
        font-size: 1.3rem;
    }

    .active {
        right: 0;
    }

    .nav-menu li {
        margin: .1rem;
        padding: 1.5rem;

    }

    .animated-ligne::after {
        bottom: 8px;
        left: 10px;
    }

    .animated-ligne:hover::after {
        width: calc(100% - 2rem);
    }

    .nav-menu li a {
        font-size: 1.5rem;
        color: #fff;
    }


    #nav-menu-btn {
        width: 55%;
        margin-left: auto;
        margin-right: auto;
        font-size: 2rem;
    }

    .nav-menu .social-icons {
        display: flex;
        justify-content: space-evenly;
        margin: 1rem;
        margin-top: 5rem;
    }

    .nav-menu .icon {
        color: #fff;
        font-size: 2.2rem;
    }

    .nav-menu .icon:hover {
        color: #211B59;
    }

    .hide {
        display: none;
    }

    .navbar .slide-right {
        margin-left: 45%;
        transition: .8s ease-in-out;
    }


    .language-options {
        width: 130px;
        margin-left: 15px;
    }

    .language-options li {
        padding: 1px 8px;
        font-size: 1.3rem;
        cursor: pointer;
    }

    .navbar.scrolled {
        background-color: rgb(222, 245, 255);
        height: 150px;
    }
}