.business-section {
  background-color: #F2FBFF;
}

.business-content {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-left: 70px;
}

.images-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 250px;
}

.images-container img {
  margin-left: 10px;
}

.business-info {
  margin-left: 20px;
  color: #211b59;
  margin-top: 250px;
}

.business-info h2 {
  font-size: 42px;
}

.business-info p {
  width: 80%;
}

#business-info-btn {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 5px 15px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 5px;
  overflow: hidden;
  background: #211B59;
  color: ghostwhite;
  margin-top: 10px;
}

#business-info-btn span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}

#business-info-btn:hover span {
  color: #fff;
  z-index: 11;
}

#business-info-btn::before,
#business-info-btn::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#business-info-btn::before {
  content: "";
  background: #0277B5;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

#business-info-btn:hover::before {
  transform: translate3d(100%, 0, 0);
}

@media (max-width: 768px) {
  .business-section {
    min-width: 100%;
    }

  .business-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 50%;
  }

  .images-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .images-container img {
   display: none;
  }

  .business-info h2 {
    font-size: 40px;
  }

  .business-info p {
    font-size: 20px;
  }

  #business-info-btn {
    font-size: 20px;
  }

}

@media (min-width: 769px) and (max-width: 992px) {
  .business-section {
    min-width: 196.5%;
  }

  .business-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 78%;
  }

  .images-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .images-container img {
    margin: 5px;
    width: 250px;
    height: 250px;
  }

  .business-info h2 {
    font-size: 50px;
  }

  .business-info p {
    font-size: 30px;
    width: 110%;
  }

  #business-info-btn {
    font-size: 25px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .business-section{
    width: 100%;
    min-height: 100vh;
  }

  .images-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 200px;
  }
}