.programs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #F2FBFF;
}

.contentPrograms {
  margin-top: 180px;
}

.programs h1 {
  text-align: center;
  color: #211b59;
  margin-bottom: 2rem;
}

#container {
  background: rgb(222, 245, 255);
  text-align: center;
  border-radius: 80px;
  overflow: hidden;
  height: 350px;
  width: 70rem;
  margin-top: 70px;
}

.product-details {
  position: relative;
  text-align: left;
  overflow: hidden;
  padding: 30px;
  height: 100%;
  float: left;
  width: 60%;
  margin: 0 auto;
  margin-top: 20px;
}

#container .product-details h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  color: #211b59;
  margin: 0;
  margin-bottom: 10px;
}


#container .product-details>p {
  text-align: center;
  font-size: 20px;
  color: #211b59;
  text-align: justify;

}

.product-image {
  transition: all 0.3s ease-out;
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 33%;
  display: inline-block;
  margin: 0 auto;
  left: 40px;
}

#container img {
  width: 100%;
  height: 100%;
}

.button-containerPrograms {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-containerPrograms a {
  color: #0277b5;
  margin-left: 10px;
}

.ctaPrograms {
  border: none;
  background: none;
}

.ctaPrograms span {
  padding-bottom: 7px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
  font-family: 'Franklin Gothic Medium';
}

.ctaPrograms svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.ctaPrograms:hover svg {
  transform: translateX(0);
}

.ctaPrograms:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: #211b59;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #211b59;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.ctaPrograms:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.open-enrollment-link {
  cursor: pointer;
}




@media (max-width: 768px) {
  .programs {
    width: 100%;
    min-height: 100vh;
  }

  #container {
    width: 100%;
    margin-top: 20px;
    height: 100%;
  }

  #container .product-details h1{
    font-size: 30px;
  }

  #container .product-details>p {
    font-size: 25px;
  }

  .ctaPrograms {
    font-size: 23px;
  }

  .ctaPrograms span {
    font-size: 15px;
  }

  .ctaPrograms svg {
    font-size: 15px;
}

  .button-containerPrograms a {
    font-size: 15px;
  }

  .product-details {
    width: 100%;
  }

  .product-image {
    width: 100%;
    left: 0;
  }

}


@media (min-width: 1024px) and (max-width: 1366px) {
  .programs {
    width: 100%;
    min-height: 100vh;
  }
}


@media (min-width: 769px) and (max-width: 992px) {
  .programs {
    width: 100%;
    min-height: 100vh;
  }
  #container {
    width: 100%;
    margin-top: 20px;
    height: 100%;
  }

  #container .product-details>p {
    font-size: 25px;
  }

  .ctaPrograms {
    font-size: 23px;
  }

  .ctaPrograms span {
    font-size: 23px;
  }

  .button-containerPrograms a {
    font-size: 25px;
  }

  .product-details {
    width: 100%;
  }

  .product-image {
    width: 100%;
    left: 0;
  }
}