.advantages-container {
  text-align: center;
  background-color: #F2FBFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.content-advantages {
  margin-top: 100px;
}

.advantages-title {
  color: #211b59;
}

.advantages-subtitle {
  font-size: 24px;
  margin-top: 20px;
  color: #211b59;
  text-align: center;
}

.advantages-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.advantages-card {
  flex: 0 0 350px;
  padding: 20px;
  background-color: #C6EEFF;
  border-radius: 15px;
  margin-right: 10px;
}

.advantages-card-NFT {
  background-color: #C6EEFF;
  border-radius: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 720px;
  height: 200px;
}

.advantages-card-NFT h4 {
  font-size: 18px;
  margin-right: 50px;
  color: #211b59;
}

.advantages-card-NFT h4 strong {
  font-weight: bold;
}

.advantages-card h3 {
  font-size: 28px;
  margin-bottom: 10px;
}

.advantages-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.advantages-card li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.advantages-card img {
  margin-right: 10px;
  width: 120px;
  height: 120px;
}

.advantages-card span {
  flex-grow: 1;
}

.advantages-card p {
  font-size: 20px;
}

.advantages-card:nth-child(1) h3,
.advantages-card:nth-child(2) h3 {
  background-color: #0277B5;
  border-radius: 15px;
  padding: 10px;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
}

.advantages-card h4 {
  color: #211b59;
  font-size: 17px;
}

.advantages-card h2 {
  color: #211b59;
  font-size: 30px;
}

@media (max-width: 768px) {

  .advantages-container {
    text-align: center;
    min-width: 100%;
    min-height: 160vh;
  }

  .content-advantages {
    width: 50%;
  }

  .advantages-card {
    margin-bottom: 20px;
  }

  .advantages-title {
    font-size: 3rem;
  }

  .advantages-subtitle {
    font-size: 25px;
  }

  .advantages-card {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .advantages-card h2 {
    font-size: 2rem;
  }

  .advantages-card h3 {
    font-size: 20px;
  }

  .advantages-card h4 {
    font-size: 15px;
  }

  .advantages-card ul {
    font-size: 16px;
  }

  .advantages-card img {
    width: 100px;
    height: 100px;
  }

  .advantages-card-NFT {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .advantages-card-NFT img {
    transform: scale(1);
  }


  .advantages-card-NFT h4 {
    font-size: 18px;
    margin-right: 0;
    text-align: justify;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .advantages-container {
    text-align: center;
    width: 200%;
    min-height: 160vh;
  }

  .content-advantages {
    width: 70%;
  }

  .advantages-card {
    margin-bottom: 20px;
  }

  .advantages-title {
    font-size: 5.2rem;
  }

  .advantages-subtitle {
    font-size: 40px;
  }

  .advantages-card {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .advantages-card h2 {
    font-size: 3rem;
  }

  .advantages-card h3 {
    font-size: 35px;
  }

  .advantages-card h4 {
    font-size: 25px;
  }

  .advantages-card ul {
    font-size: 16px;
  }

  .advantages-card img {
    width: 250px;
    height: 250px;
  }

  .advantages-card-NFT {
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .advantages-card-NFT img {
    transform: scale(1.8);
  }


  .advantages-card-NFT h4 {
    font-size: 25px;
    margin-right: 0;
    text-align: center;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .advantages-container  {
    width: 100%;
    min-height: 100vh;
  }
}